import React, { useState, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import { Column, useTable, useSortBy } from "react-table";
import { Tenant, Account, Site, Policy } from "pages/Provision/Provision.type";
import { FetchSiteByOperationsContext } from "pages/Provision/Provision";
import Table from "pages/Provision/tables/Table";

import Modal from "react-modal";
import PolicyForm from "pages/Provision/forms/PolicyForm";
import EditPolicyForm from "pages/Provision/forms/EditPolicyForm";
import { FiEdit2 } from "react-icons/fi";
import "pages/Provision/Provision.css";

type SiteTableProps = {
  tenant: Tenant;
  account: Account;
  site: Site;
  policies: Policy[];
};

type columnData = {
  policyResName: string;
  policyName: string;
  operatingStartEndDate: string;
  operatingDaysOfWeek: string;
  operatingDayStartEndTime: string;
  timeZone: string;
  policyStatus: string;
  createTime: string;
  lastUpdateTime: string;
};

const PolicyTable: React.FC<SiteTableProps> = ({
  tenant,
  account,
  site,
  policies,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState<Policy>(null!);
  const [searchQuery, setSearchQuery] = useState("");
  const fetchSites = useContext(FetchSiteByOperationsContext);

  const data = useMemo(
    () =>
      policies
        .filter((policy) => policy.siteResName === site.siteResName)
        .map((policy) => ({
          ...policy,
          createTime: new Date(policy.creationTime.epochTime).toLocaleString(),
          lastUpdateTime: new Date(
            policy.lastUpdateTime.epochTime,
          ).toLocaleString(),
          monitoredThrshold: `${policy.monitoredThreshold.threshold} ${policy.monitoredThreshold.unit} ${policy.monitoredThreshold.operator}`,
          monitoringFrequency: `${policy.monitoringFrequency.timePeriod} ${policy.monitoringFrequency.unitOfTime}`,
          transmissionFrequency: `${policy.transmissionFrequency.timePeriod} ${policy.transmissionFrequency.unitOfTime}`,
          operatingStartEndDate: `${new Date(
            policy.operationalPeriod.periodStartDate.epochTime,
          ).toLocaleDateString()} - ${new Date(
            policy.operationalPeriod.periodEndDate.epochTime,
          ).toLocaleDateString()}`,
          operatingDaysOfWeek:
            policy.operationalPeriod.operatingDaysOfWeek.join(", "),
          operatingDayStartEndTime: `${policy.operationalPeriod.operatingDayStartTime} - ${policy.operationalPeriod.operatingDayEndTime}`,
          timeZone: policy.operationalPeriod.localTimezoneOffset.toString(),
        }))
        .filter((policy) => {
          return (
            policy.policyResName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            policy.policyName.toLowerCase().includes(searchQuery.toLowerCase())
          );
        }),
    [tenant, account, site, policies, searchQuery],
  );

  const columns: Column<columnData>[] = React.useMemo(
    () => [
      { Header: "PolicyResName", accessor: "policyResName" },
      { Header: "PolicyName", accessor: "policyName" },
      { Header: "OperatingStartEndDate", accessor: "operatingStartEndDate" },
      { Header: "OperatingDaysOfWeek", accessor: "operatingDaysOfWeek" },
      {
        Header: "OperatingDayStartEndTime",
        accessor: "operatingDayStartEndTime",
      },
      { Header: "TimezoneOffset", accessor: "timeZone" },
      { Header: "Status", accessor: "policyStatus" },
      { Header: "CreateTime", accessor: "createTime" },
      { Header: "LastUpdateTime", accessor: "lastUpdateTime" },
      {
        Header: "Action",
        Cell: ({ row }: { row: any }) => (
          <div>
            <button
              onClick={() => {
                setSelectedPolicy(
                  policies.filter(
                    (policy) =>
                      policy.policyResName === row.original.policyResName,
                  )[0],
                );
                setEditModalIsOpen(true);
              }}
            >
              <FiEdit2 />
            </button>
          </div>
        ),
      },
    ],
    [tenant, account, site, policies],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <div>
      <div className="header-container">
        <h1>Policies</h1>
        <button
          onClick={() => {
            setModalIsOpen(true);
          }}
        >
          + Policy
        </button>
      </div>

      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/scrappy/provision">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/scrappy/provision/${tenant.customerResName}`}>
                {tenant.customerName}
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link
                to={`/scrappy/provision/${tenant.customerResName}/${account.accountResName}`}
              >
                {account.accountName}
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {site.siteName}
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Policies
            </li>
          </ol>
        </nav>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <PolicyForm account={account.accountResName} site={site.siteResName} />
      </Modal>

      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={() => setEditModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <EditPolicyForm selectedPolicy={selectedPolicy} />
      </Modal>

      <div className="table-header-container">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={() => setSearchQuery("")}>Clear</button>
        <button
          className="button-end"
          onClick={() =>
            fetchSites(account.accountResName, site.siteResName, ["policies"])
          }
        >
          Refresh
        </button>
      </div>
      <Table
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      />
    </div>
  );
};

export default PolicyTable;
