import { useState, useContext } from "react";
import { Calendar } from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";
import { signRequest } from "utils/utils";
import { FetchSiteByOperationsContext } from "pages/Provision/Provision";

interface FormErrors {
  calendarName?: string;
  year?: string;
  status?: string;
}

const CalendarForm: React.FC<{ account: string; site: string }> = ({
  account,
  site,
}) => {
  const [calendar, setCalendar] = useState<Calendar>({
    customerResName: "",
    accountResName: account,
    siteResName: site,
    calendarResName: "",
    calendarName: "",
    status: "active",
    year: new Date().getFullYear(),
    creationTime: { epochTime: 0 },
    lastUpdateTime: { epochTime: 0 },
  });

  const [error, setError] = useState<string>("");
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState<boolean>(false);
  const fetchSites = useContext(FetchSiteByOperationsContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    const { name, value } = event.target;
    setCalendar({ ...calendar, [name]: value });
  };

  const postCalendar = async () => {
    setError("");
    setLoading(true);

    const url = `/accounts/${account}/sites/${site}/calendars`;
    const method = "POST";
    const host = "SITEHOURS";

    const body = {
      calendarName: calendar.calendarName,
      year: Number(calendar.year),
      status: calendar.status,
    };

    try {
      const signedRequest = await signRequest(host, url, method, body);
      const response = await fetch(signedRequest.url, signedRequest);

      if (!response.ok) {
        setError("Failed to create calendar");
        console.error("Failed to create calendar", response);
      } else {
        const createdCalendar = await response.json();
        console.log("Created calendar", createdCalendar);

        createdCalendar.errorMessage
          ? setError("Failed to create calendar")
          : fetchSites(account, site, ["calendars"]);
        setLoading(false);
      }
    } catch (error) {
      setError("Failed to create calendar");
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const validateForm = () => {
    const errors: FormErrors = {};

    if (!calendar.calendarName) {
      errors.calendarName = "Calendar name is required";
    }

    if (!calendar.year) {
      errors.year = "Year is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    await postCalendar();
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Add Calendar</h2>
      {loading && (
        <div className="loader-container">
          <div className="loader" />
        </div>
      )}
      {loading && <label className="post">Adding Calendar...</label>}
      {error && <label className="error">{error}</label>}
      <label>
        Name:
        <input
          type="text"
          name="calendarName"
          value={calendar.calendarName}
          onChange={handleChange}
        />
      </label>
      {formErrors.calendarName && (
        <label className="form-error">{formErrors.calendarName}</label>
      )}
      <label>
        Year:
        <input
          type="number"
          name="year"
          value={calendar.year}
          onChange={handleChange}
        />
      </label>
      {formErrors.year && (
        <label className="form-error">{formErrors.year}</label>
      )}
      <input type="submit" value="Add Calendar" />
    </form>
  );
};

export default CalendarForm;
