import React, { useState, useMemo, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Column, useTable, useSortBy } from "react-table";
import {
  Tenant,
  Account,
  Site,
  Calendar,
} from "pages/Provision/Provision.type";
import { FetchSiteByOperationsContext } from "pages/Provision/Provision";
import Table from "pages/Provision/tables/Table";

import Modal from "react-modal";
import CalendarForm from "pages/Provision/forms/CalendarForm";

import { FaMagnifyingGlass } from "react-icons/fa6";
import "pages/Provision/Provision.css";

type SiteTableProps = {
  tenant: Tenant;
  account: Account;
  site: Site;
  calendars: Calendar[];
};

type columnData = {
  calendarResName: string;
  calendarName: string;
  year: string;
  status: string;
  createTime: string;
  lastUpdateTime: string;
};

const CalendarTable: React.FC<SiteTableProps> = ({
  tenant,
  account,
  site,
  calendars,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const fetchSites = useContext(FetchSiteByOperationsContext);
  const navigate = useNavigate();

  const data = useMemo(
    () =>
      calendars
        .filter((calendar) => calendar.siteResName === site.siteResName)
        .map((calendar) => ({
          ...calendar,
          createTime: new Date(
            calendar.creationTime.epochTime,
          ).toLocaleString(),
          lastUpdateTime: new Date(
            calendar.lastUpdateTime.epochTime,
          ).toLocaleString(),
          year: calendar.year.toString(),
        }))
        .filter((calendar) => {
          return (
            calendar.calendarResName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            calendar.calendarName
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
          );
        }),
    [tenant, account, site, calendars, searchQuery],
  );

  const columns: Column<columnData>[] = React.useMemo(
    () => [
      { Header: "CalendarResName", accessor: "calendarResName" },
      { Header: "CalendarName", accessor: "calendarName" },
      { Header: "Year", accessor: "year" },
      { Header: "Status", accessor: "status" },
      { Header: "CreateTime", accessor: "createTime" },
      { Header: "LastUpdateTime", accessor: "lastUpdateTime" },
      {
        Header: "Action",
        Cell: ({ row }: { row: any }) => (
          <div>
            <button onClick={() => navigate(`${row.original.calendarResName}`)}>
              <FaMagnifyingGlass />
            </button>
          </div>
        ),
      },
    ],
    [tenant, account, site, calendars],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <div>
      <div className="header-container">
        <h1>Calendars</h1>
        <button
          onClick={() => {
            setModalIsOpen(true);
          }}
        >
          + Calendar
        </button>
      </div>

      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/scrappy/provision">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/scrappy/provision/${tenant.customerResName}`}>
                {tenant.customerName}
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link
                to={`/scrappy/provision/${tenant.customerResName}/${account.accountResName}`}
              >
                {account.accountName}
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {site.siteName}
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Calendars
            </li>
          </ol>
        </nav>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <CalendarForm
          account={account.accountResName}
          site={site.siteResName}
        />
      </Modal>

      <div className="table-header-container">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={() => setSearchQuery("")}>Clear</button>
        <button
          className="button-end"
          onClick={() =>
            fetchSites(account.accountResName, site.siteResName, ["calendars"])
          }
        >
          Refresh
        </button>
      </div>
      <Table
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      />
    </div>
  );
};

export default CalendarTable;
