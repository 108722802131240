import { Signer } from "aws-amplify";

export type SessionCredentialsProps = {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
};

export type MethodProps = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

export type SignRequestProps = {
  url: string;
  body: string;
  headers: {
    "X-Amz-Date": string;
    "X-Amz-Security-Token": string;
    Authorization: string;
  };
};

const APIGatewayLinks = {
  TENANTS: import.meta.env.VITE_TENANTS_BASE_URL,
  SITES: import.meta.env.VITE_SITE_DEVICES_BASE_URL,
  ASSETS: import.meta.env.VITE_SITE_ASSETS_BASE_URL,
  USERS: import.meta.env.VITE_USER_API_LINK,
  MAPS: import.meta.env.VITE_MAPS_BASE_URL,
  SITEHOURS: import.meta.env.VITE_SITE_OPERATIONS_API_LINK,
};

export const getSessionCredentials = (): SessionCredentialsProps => {
  const accessKey = localStorage.getItem("accessKey") ?? "";
  const secretAccessKey = localStorage.getItem("secretAccessKey") ?? "";
  const sessionToken = localStorage.getItem("sessionToken") ?? "";

  return {
    accessKeyId: accessKey,
    secretAccessKey: secretAccessKey,
    sessionToken: sessionToken,
  };
};

export const signRequest = async (
  _host: keyof typeof APIGatewayLinks,
  url: string,
  method: MethodProps,
  data?: unknown,
): Promise<SignRequestProps> => {
  const serviceInfo = {
    region: import.meta.env.VITE_AWS_REGION,
    service: import.meta.env.VITE_AWS_SERVICE_NAME,
  };

  const { secretAccessKey, accessKeyId, sessionToken } =
    await getSessionCredentials();

  const params = {
    method,
    url: APIGatewayLinks[_host] + url,
    data: data ? JSON.stringify(data) : data,
  };

  const credentials = {
    secret_key: secretAccessKey.replace(/"/g, ""),
    access_key: accessKeyId.replace(/"/g, ""),
    session_token: sessionToken.replace(/"/g, ""),
  };

  const signedRequest = await Signer.sign(params, credentials, serviceInfo);
  delete signedRequest.headers["host"];

  if (method === "POST" || method === "PATCH" || method === "PUT") {
    signedRequest.headers["Content-Type"] = "application/json";
  }

  return signedRequest;
};
