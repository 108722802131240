import React, { useState, useContext } from "react";
import { Policy, DayOfWeek } from "pages/Provision/Provision.type";
import { signRequest } from "utils/utils";
import { FetchSiteByOperationsContext } from "pages/Provision/Provision";

interface EditPolicyFormProps {
  selectedPolicy: Policy;
}

interface FormErrors {
  operationsDaysOfWeek?: string;
  operationDayStartTime?: string;
  operationDayEndTime?: string;
  localTimezoneOffset?: string;
}

const EditPolicyForm: React.FC<EditPolicyFormProps> = ({ selectedPolicy }) => {
  const [policy, setPolicy] = useState<Policy>({ ...selectedPolicy });
  const [error, setError] = useState<string>("");
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState<boolean>(false);
  const fetchSites = useContext(FetchSiteByOperationsContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "operatingDaysOfWeek") {
      const days = value.split(",").map((day) => day.trim() as DayOfWeek);
      setPolicy({
        ...policy,
        operationalPeriod: {
          ...policy.operationalPeriod,
          operatingDaysOfWeek: days,
        },
      });
    } else {
      setPolicy({
        ...policy,
        operationalPeriod: {
          ...policy.operationalPeriod,
          [name]: value,
        },
      });
    }
  };

  const validateForm = () => {
    const errors: FormErrors = {};

    const validDaysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    if (
      policy.operationalPeriod.operatingDaysOfWeek.some(
        (day) => !validDaysOfWeek.includes(day),
      )
    ) {
      errors.operationsDaysOfWeek = "Invalid day of the week";
    }

    if (!policy.operationalPeriod.operatingDayStartTime) {
      errors.operationDayStartTime = "Start time is required";
    }

    if (!policy.operationalPeriod.operatingDayEndTime) {
      errors.operationDayEndTime = "End time is required";
    }

    if (!policy.operationalPeriod.localTimezoneOffset) {
      errors.localTimezoneOffset = "Timezone is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const patchPolicy = async () => {
    setError("");
    setLoading(true);

    const url = `/accounts/${selectedPolicy.accountResName}/sites/${selectedPolicy.siteResName}/policies/${selectedPolicy.policyResName}`;
    const method = "PATCH";
    const host = "SITEHOURS";

    const body = {
      monitoredThreshold: policy.monitoredThreshold,
      monitoringFrequency: policy.monitoringFrequency,
      transmissionFrequency: policy.transmissionFrequency,
      policyName: policy.policyName,
      status: policy.policyStatus,
      dataType: policy.dataType,
      operationalPeriod: {
        ...policy.operationalPeriod,
        localTimezoneOffset: parseInt(
          policy.operationalPeriod.localTimezoneOffset.toString(),
          10,
        ),
      },
      logLevel: policy.logLevel,
    };

    try {
      const signedRequest = await signRequest(host, url, method, body);
      const response = await fetch(signedRequest.url, signedRequest);

      if (!response.ok) {
        setError("Failed to update policy");
        console.error("Failed to update policy", response);
      } else {
        const createdPolicy = await response.json();
        console.log("Updated policy", createdPolicy);

        createdPolicy.errorMessage
          ? setError("Failed to create policy")
          : fetchSites(policy.accountResName, policy.siteResName, ["policies"]);
        setLoading(false);
      }
    } catch (error) {
      setError("Failed to create policy");
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormErrors({});
    if (!validateForm()) {
      return;
    }
    await patchPolicy();
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Edit Policy</h2>
      {loading && (
        <div className="loader-container">
          <div className="loader" />
        </div>
      )}
      {loading && <label className="post">Updating Policy...</label>}
      {error && <label className="error">{error}</label>}
      <label>
        Operating Days of Week:
        <input
          type="text"
          name="operatingDaysOfWeek"
          value={policy.operationalPeriod.operatingDaysOfWeek.join(",")}
          onChange={handleChange}
        />
      </label>
      {formErrors.operationsDaysOfWeek && (
        <label className="form-error">{formErrors.operationsDaysOfWeek}</label>
      )}
      <label>
        Operating Day Start Time:
        <input
          type="text"
          name="operatingDayStartTime"
          value={policy.operationalPeriod.operatingDayStartTime}
          onChange={handleChange}
        />
      </label>
      {formErrors.operationDayStartTime && (
        <label className="form-error">{formErrors.operationDayStartTime}</label>
      )}
      <label>
        Operating Day End Time:
        <input
          type="text"
          name="operationDayEndTime"
          value={policy.operationalPeriod.operatingDayEndTime}
          onChange={handleChange}
        />
      </label>
      {formErrors.operationDayEndTime && (
        <label className="form-error">{formErrors.operationDayEndTime}</label>
      )}
      <label>
        Local Timezone Offset:
        <input
          type="number"
          name="localTimezoneOffset"
          value={policy.operationalPeriod.localTimezoneOffset}
          onChange={handleChange}
        />
      </label>
      {formErrors.localTimezoneOffset && (
        <label className="form-error">{formErrors.localTimezoneOffset}</label>
      )}
      <input type="submit" value="Update Policy" />
    </form>
  );
};

export default EditPolicyForm;
