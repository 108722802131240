import React, { useState, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import { Column, useTable, useSortBy } from "react-table";
import {
  Tenant,
  Account,
  Site,
  Calendar,
  Shift,
} from "pages/Provision/Provision.type";
import { FetchSiteByOperationsContext } from "pages/Provision/Provision";
import Table from "pages/Provision/tables/Table";

import "pages/Provision/Provision.css";

type SiteTableProps = {
  tenant: Tenant;
  account: Account;
  site: Site;
  calendar: Calendar;
  shifts: Shift[];
};

type columnData = {
  shiftResName: string;
  shiftName: string;
  shiftType: string;
  status: string;
  shiftStartTime: string;
  shiftEndTime: string;
  shiftPeriods: string;
};

const ShiftTable: React.FC<SiteTableProps> = ({
  tenant,
  account,
  site,
  calendar,
  shifts,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const fetchSites = useContext(FetchSiteByOperationsContext);

  const data = useMemo(
    () =>
      shifts
        .filter((shift) => shift.calendarResName === calendar.calendarResName)
        .map((shift) => ({
          ...shift,
          shiftStartTime: `${shift.shiftStartTime.shiftDate} ${shift.shiftStartTime.shiftTime}`,
          shiftEndTime: `${shift.shiftEndTime.shiftDate} ${shift.shiftEndTime.shiftTime}`,
          shiftPeriods: shift.shiftPeriods
            .map(
              (period) =>
                `${period.startTime.shiftDate} ${period.startTime.shiftTime} - ${period.endTime.shiftDate} ${period.endTime.shiftTime}`,
            )
            .join(", "),
        }))
        .filter((shift) => {
          return (
            shift.shiftResName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            shift.shiftName.toLowerCase().includes(searchQuery.toLowerCase())
          );
        }),
    [tenant, account, site, calendar, shifts, searchQuery],
  );

  const columns: Column<columnData>[] = React.useMemo(
    () => [
      { Header: "ShiftResName", accessor: "shiftResName" },
      { Header: "ShiftName", accessor: "shiftName" },
      { Header: "ShiftType", accessor: "shiftType" },
      { Header: "ShiftStartTime", accessor: "shiftStartTime" },
      { Header: "ShiftEndTime", accessor: "shiftEndTime" },
      { Header: "ShiftPeriods", accessor: "shiftPeriods" },
      { Header: "Status", accessor: "status" },
    ],
    [tenant, account, site, calendar, shifts],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <div>
      <div className="header-container">
        <h1>Shifts</h1>
      </div>

      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/scrappy/provision">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/scrappy/provision/${tenant.customerResName}`}>
                {tenant.customerName}
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link
                to={`/scrappy/provision/${tenant.customerResName}/${account.accountResName}`}
              >
                {account.accountName}
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {site.siteName}
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {calendar.calendarName}
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Shifts
            </li>
          </ol>
        </nav>
      </div>

      <div className="table-header-container">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={() => setSearchQuery("")}>Clear</button>
        <button
          className="button-end"
          onClick={() =>
            fetchSites(account.accountResName, site.siteResName, ["calendars"])
          }
        >
          Refresh
        </button>
      </div>
      <Table
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      />
    </div>
  );
};

export default ShiftTable;
